import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { Row, Col } from "react-bootstrap"

import Layout from "../components/Layout.js"
import Slider from "../components/SliderHome.js"
import arrowDown from "../images/icons/arrowDown.png"
import SplitSection from "../components/SplitSection.js"
import InfoSection from "../components/InfoSection.js"
import InfoAndBackground from "../components/InfoAndBackground"
import AgeGate from "../components/AgeGate"
import Cookies from "js-cookie"
import { IsMobileDevice } from "../utils/index.js"

const Home = ({ data }) => {
  const [listSlider, setListSlider] = useState([])
  const [age, setAge] = useState(true)

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  useEffect(() => {
    let td = Cookies.get("_td")
    window.dataLayer.push({
      brand: "Budweiser",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Home",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.budweiser.com.ar/",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "" ,
    })
  }, [])

  useEffect(() => {
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    if (cookie || local) setAge(false)
    setListSlider(
      data.Slider.edges.sort((a, b) => a.node.position - b.node.position)
    )
    // setListSlider([data.Slider.edges[1].node,data.Slider.edges[0].node] );
  }, [])

  return !age ? (
    <Layout style={{ background: "black" }}>
      <SEO title="Home" />
      {/* arrowDown */}
      <div style={{ backgroundColor: "#fff" }}>
        <div className="fade-out"></div>
        <InfoAndBackground
          isMobile={isMobile}
          info={data.imageFooter.edges[0].node}
          image={data.allFile.edges[1].node.childImageSharp.fluid}
        />
      </div>
      <Row className="d-flex justify-content-center m-0">
        <Col xs={"auto"}>
          <a
            onClick={e =>
              document
                .querySelector("#InfoSection")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <img src={arrowDown} className="ArrowDown" />
          </a>
        </Col>
      </Row>
      {/* info birra */}
      <SplitSection
          image={isMobile ? data.Middle.edges[0].node.imageMobile : data.Middle.edges[0].node.imageDesktop}
        left={
          <InfoSection
            title={data.Middle.edges[0].node.title}
            paragraph={data.Middle.edges[0].node.paragraph}
            // button="Conocé más"
          />
        }
        right={null
          // <img src={data.Middle.edges[0].node.imageDesktop} style={{ objectFit: "cover", height: "100%", width: "100%" }}/>
          // <Img
          //   fluid={data.MiddleHome.edges[0].node.childImageSharp.fluid}
          //   loading="eager"
          //   style={{ height: "100%" }}
          // />
        }
      />
      {/* backgroundFooter */}
      <Slider sliders={listSlider} />
    </Layout>
  ) : (
    <>
      <SEO title="La Música En Tus Manos" description="Esta es la web de la cerveza de la música. Disfrutá una lager suave y refrescante mientras encontrás los mejores eventos de artistas internacionales en el país."/>
      <AgeGate path="/" />
    </>
  )
}

export default Home

export const data = graphql`
  query ImgHome {
    allFile(filter: { relativeDirectory: { eq: "Home" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    MiddleHome: allFile(filter: { relativeDirectory: { eq: "MiddleHome" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Slider: allHomeSlider {
      edges {
        node {
          title
          paragraph
          textButton
          linkButton
          position
          imageDesktop
          imageMobile
          isVideo
        }
      }
    }
    Middle: allHomeMiddle {
      edges {
        node {
          title
          paragraph
          imageDesktop
          imageMobile
        }
      }
    }
    imageFooter: allHomeImage {
      edges {
        node {
          title
          paragraph
          textButton
          linkButton
          imageDesktop
          imageMobile
        }
      }
    }
  }
`
