import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import styles from "../scss/components/_SliderItem.module.scss"
import ReactPlayer from "react-player"

const SliderItem = ({ slider, isMobile }) => {
  const [playing, setPlaying] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setPlaying(true)
    }, 100)
  }, [])

  return (
    <Fragment>
      {/* image background */}

      {slider.isVideo ? (
        <ReactPlayer
          style={{ position: "absolute", marginTop:"0vh" }}
          playing={false}
          width={"100vw"}
          height={"90vh"}
          muted={false}
          playsinline
          volume={1}
        //    url='https://www.youtube.com/watch?v=SmkTr_6xF2c'
          url="https://youtu.be/NxC6fpLQq_8?si=eUHzb9gYnonF2efP"
        />
      ) : !isMobile ? (
        <img src={slider.imageDesktop} className={styles.imgBackground} />
      ) : (
        <img src={slider.imageMobile} className={styles.imgBackground} />
      )}
      {/* gradient */}
      <div className={styles.gradient}>
        {/* info */}
        <Container fluid className={styles.SlideContainer}>
          <Row className={styles.SlideRowText}>
            {!slider.isVideo && (
              <Col md={"7"} xs={"10"} className={styles.SlideColText}>
                {/* title */}
                {slider.title && (
                  <Row>
                    <Col>
                      <h1 className={styles.infoTitle}>{slider.title}</h1>
                    </Col>
                  </Row>
                )}
                {/* paragraph */}
                {slider.paragraph && (
                  <Row>
                    <Col>
                      <p className={styles.infoParagraph}>{slider.paragraph}</p>
                    </Col>
                  </Row>
                )}
                {/* button */}
                {slider.textButton && (
                  <Row>
                    <Col>
                      <Button
                        className={styles.infoButton}
                        href={slider.linkButton}
                      >
                        {slider.textButton}
                      </Button>
                    </Col>
                  </Row>
                )}
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default SliderItem
